import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Latex from "react-latex";
import { Button } from "antd";
import { PlayCircleFilled } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import color from "~styles/color";
import citations from "./citations.json";
import "antd/dist/antd.css";
import { GoRepo } from "@react-icons/all-files/go/GoRepo";
import { AiOutlineStar } from "@react-icons/all-files/ai/AiOutlineStar";
import { VscRepoForked } from "@react-icons/all-files/vsc/VscRepoForked";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub";
import { GoLocation } from "@react-icons/all-files/go/GoLocation";

import { AiOutlineRight } from "@react-icons/all-files/ai/AiOutlineRight";
import { AiOutlineLeft } from "@react-icons/all-files/ai/AiOutlineLeft";

import { IoNewspaperOutline } from "@react-icons/all-files/io5/IoNewspaperOutline";
import { IoBookOutline } from "@react-icons/all-files/io5/IoBookOutline";

import { StaticImage } from "gatsby-plugin-image";
import { Emoji } from "emoji-mart";

import AI2_LOGO from "~icons/ai2-small.svg";
import UW_CSE_LOGO from "~icons/uw-small.svg";

import S2_ICON from "~icons/semantic-scholar.svg";
import S2_DARK_ICON from "~icons/s2-dark.svg";
import GS_ICON from "~icons/google-scholar.svg";

import PDF_ICON from "~icons/pdf.svg";
import YOUTUBE_ICON from "~icons/youtube.svg";

import GH_ICON from "~icons/github.svg";
import TWITTER_ICON from "~icons/twitter.svg";
import GH_DARK_ICON from "~icons/github-dark.svg";
import GH_LONG from "~icons/github-long.svg";

import CITATIONS_ICON from "~icons/citations.svg";
import S2_LONG_ICON from "~icons/semantic-scholar-long.svg";

import EAI_COVER from "~icons/eai-cover.svg";
import AI2_THOR_ICON from "~icons/ai2thor.svg";

import CS231n from "~icons/cs231n-cover.svg";
import CSE455 from "~icons/computer-vision-cover.svg";
import CS230 from "~icons/cs230-cover.svg";

import "@allenai/varnish/dist/theme.css";

const authorMap = {
  "Matt Deitke": "//semanticscholar.org/author/Matt-Deitke/1632916259",
  "Rose Hendrix": "//semanticscholar.org/author/Rose-Hendrix/2008211857",
  "Winson Han": "//semanticscholar.org/author/Winson-Han/1443358534",
  "Alvaro Herrasti": "//semanticscholar.org/author/Alvaro-Herrasti/3360135",
  "Aniruddha Kembhavi":
    "//semanticscholar.org/author/Aniruddha-Kembhavi/2684226",
  "Eric Kolve": "//semanticscholar.org/author/Eric-Kolve/3386570",
  "Roozbeh Mottaghi": "//semanticscholar.org/author/R.-Mottaghi/3012475",
  "Kiana Ehsani": "//www.semanticscholar.org/author/Kiana-Ehsani/2883417",
  "Jordi Salvador": "//semanticscholar.org/author/Jordi-Salvador/145704057",
  "Dustin Schwenk": "//semanticscholar.org/author/Dustin-Schwenk/34846449",
  "Eli VanderBilt": "//semanticscholar.org/author/Eli-VanderBilt/1632920625",
  "Matthew Wallingford":
    "//semanticscholar.org/author/Matthew-Wallingford/1632957174",
  "Luca Weihs": "//semanticscholar.org/author/Luca-Weihs/20745881",
  "Mark Yatskar": "//semanticscholar.org/author/Mark-Yatskar/2064210",
  "Ali Farhadi": "//semanticscholar.org/author/Ali-Farhadi/143787583",
  "Dhruv Batra": "//semanticscholar.org/author/Dhruv-Batra/1746610",
  "Yonatan Bisk": "//semanticscholar.org/author/Yonatan-Bisk/3312309",
  "Tommaso Campari": "//semanticscholar.org/author/Tommaso-Campari/2157858068",
  "Angel X. Chang": "//semanticscholar.org/author/Angel-X.-Chang/145830541",
  "Devendra Singh Chaplot":
    "//semanticscholar.org/author/Devendra-Singh-Chaplot/2142753065",
  "Changan Chen": "//semanticscholar.org/author/Changan-Chen/153246625",
  "Claudia P\u00e9rez D'Arpino":
    "//semanticscholar.org/author/Claudia-P'erez-D'Arpino/2187686406",
  "Li Fei-Fei": "//semanticscholar.org/author/Li-Fei-Fei/48004138",
  "Anthony Francis": "//semanticscholar.org/author/Anthony-Francis/144023916",
  "Chuang Gan": "//semanticscholar.org/author/Chuang-Gan/2056157586",
  "Kristen Grauman": "//semanticscholar.org/author/K.-Grauman/1794409",
  "David Hall": "//semanticscholar.org/author/David-Hall/1390002216",
  "Unnat Jain": "//semanticscholar.org/author/Unnat-Jain/10680632",
  "Jacob Krantz": "//semanticscholar.org/author/Jacob-Krantz/51050450",
  "Stefan Lee": "//semanticscholar.org/author/Stefan-Lee/2297229",
  "Chengshu Li": "//semanticscholar.org/author/Chengshu-Li/2128669132",
  "Sagnik Majumder": "//semanticscholar.org/author/Sagnik-Majumder/47927907",
  "Oleksandr Maksymets":
    "//semanticscholar.org/author/Oleksandr-Maksymets/90536527",
  "Roberto Mart\u00edn-Mart\u00edn":
    "//semanticscholar.org/author/Roberto-Mart'in-Mart'in/2065917078",
  "Sonia Raychaudhuri":
    "//semanticscholar.org/author/Sonia-Raychaudhuri/1752600856",
  "Mike Roberts": "//semanticscholar.org/author/Mike-Roberts/2111886620",
  "Silvio Savarese": "//semanticscholar.org/author/S.-Savarese/1702137",
  "Manolis Savva": "//semanticscholar.org/author/M.-Savva/2295141",
  "Mohit Shridhar": "//semanticscholar.org/author/Mohit-Shridhar/33516562",
  "Niko S\u00fcnderhauf": "//semanticscholar.org/author/N.-Sunderhauf/9258524",
  "Andrew Szot": "//semanticscholar.org/author/Andrew-Szot/1580188581",
  "Ben Talbot": "//semanticscholar.org/author/Ben-Talbot/16823219",
  "Joshua B. Tenenbaum": "//semanticscholar.org/author/J.-Tenenbaum/1763295",
  "Jesse Thomason": "//semanticscholar.org/author/Jesse-Thomason/2665873",
  "Alexander Toshev": "//semanticscholar.org/author/Alexander-Toshev/1726415",
  "Joanne Truong": "//semanticscholar.org/author/Joanne-Truong/120389691",
  "Jiajun Wu": "//semanticscholar.org/author/Jiajun-Wu/3045089",
  "Ludwig Schmidt": "//www.semanticscholar.org/author/Ludwig-Schmidt/152772922",
  "Carl Vondrick": "//www.semanticscholar.org/author/Carl-Vondrick/1856025",
  "Georgia Gkioxari":
    "//www.semanticscholar.org/author/Georgia-Gkioxari/2082991",
  "Aditya Kusupati":
    "//www.semanticscholar.org/author/Aditya-Kusupati/52207562",
  "Vikram Voleti": "//www.semanticscholar.org/author/Vikram-S.-Voleti/2961618",
  "Ruoshi Liu": "//www.semanticscholar.org/author/Ruoshi-Liu/2143183492",
  "Christopher Clark":
    "https://www.semanticscholar.org/author/Christopher-Clark/143997772",
  "Sangho Lee": "https://www.semanticscholar.org/author/Sangho-Lee/2322783815",
  "Rohun Tripathi":
    "https://www.semanticscholar.org/author/Rohun-Tripathi/32483149",
  "Yue Yang": "https://www.semanticscholar.org/author/Yue-Yang/2109409802",
  "Jae Sung Park":
    "https://www.semanticscholar.org/author/Jae-Sung-Park/2303571193",
  "Mohammadreza Salehi":
    "https://www.semanticscholar.org/author/Mohammadreza-Salehi/2259938556",
  "Niklas Muennighoff":
    "https://www.semanticscholar.org/author/Niklas-Muennighoff/2037383772",
  "Kyle Lo": "https://www.semanticscholar.org/author/Kyle-Lo/2315302377",
  "Luca Soldaini":
    "https://www.semanticscholar.org/author/Luca-Soldaini/2280666145",
  "Jiasen Lu": "https://www.semanticscholar.org/author/Jiasen-Lu/2117718562",
  "Taira Anderson":
    "https://www.semanticscholar.org/author/Taira-Anderson/2322845849",
  "Erin Bransom":
    "https://www.semanticscholar.org/author/Erin-Bransom/2203427167",
  "Huong Ngo": "https://www.semanticscholar.org/author/Huong-Ngo/2223118889",
  "YenSung Chen":
    "https://www.semanticscholar.org/author/YenSung-Chen/2322736233",
  "Ajay Patel": "https://www.semanticscholar.org/author/Ajay-Patel/2109171018",
  "Chris Callison-Burch":
    "https://www.semanticscholar.org/author/Christopher-Callison-Burch/2266396865",
  "Andrew Head":
    "https://www.semanticscholar.org/author/Andrew-Head/2065039588",
  "Favyen Bastani": "https://www.semanticscholar.org/author/F.-Bastani/2995635",
  "Nathan Lambert":
    "https://www.semanticscholar.org/author/Nathan-Lambert/2052363815",
  "Yvonne Chou":
    "https://www.semanticscholar.org/author/Yvonne-Chou/2212532904",
  "Arnavi Chheda":
    "https://www.semanticscholar.org/author/Arnavi-Chheda/2322798621",
  "Jenna Sparks":
    "https://www.semanticscholar.org/author/Jenna-Sparks/2052201732",
  "Sam Skjonsberg":
    "https://www.semanticscholar.org/author/Sam-Skjonsberg/46181683",
  "Michael Schmitz":
    "https://www.semanticscholar.org/author/Michael-Schmitz/2286676842",
  "Aaron Sarnat":
    "https://www.semanticscholar.org/author/Aaron-Sarnat/35429963",
  "Byron Bischoff":
    "https://www.semanticscholar.org/author/Byron-Bischoff/2322794013",
  "Pete Walsh": "https://www.semanticscholar.org/author/Pete-Walsh/2158819969",
  "Chris Newell":
    "https://www.semanticscholar.org/author/Christopher-Newell/145541350",
  "Piper Wolters":
    "https://www.semanticscholar.org/author/Piper-Wolters/2030980148",
  "Tanmay Gupta":
    "https://www.semanticscholar.org/author/Tanmay-Gupta/2269734955",
  "Kuo-Hao Zeng":
    "https://www.semanticscholar.org/author/Kuo-Hao-Zeng/2269736837",
  "Jon Borchardt":
    "https://www.semanticscholar.org/author/Jon-Borchardt/2322805089",
  "Dirk Groeneveld":
    "https://www.semanticscholar.org/author/Dirk-Groeneveld/3458736",
  "Crystal Nam":
    "https://www.semanticscholar.org/author/Crystal-Nam/2282136595",
  "Sophie Lebrecht":
    "https://www.semanticscholar.org/author/Sophie-Lebrecht/2312474",
  "Caitlin Wittlif":
    "https://www.semanticscholar.org/author/Caitlin-Marie-Wittlif/2322805091",
  "Carissa Schoenick":
    "https://www.semanticscholar.org/author/Carissa-Schoenick/3393851",
  "Oscar Michel":
    "https://www.semanticscholar.org/author/Oscar-Michel/2196005933",
  "Ranjay Krishna":
    "https://www.semanticscholar.org/author/Ranjay-Krishna/2262217505",
  "Noah A. Smith":
    "https://www.semanticscholar.org/author/Noah-A.-Smith/2297646599",
  "Hannaneh Hajishirzi":
    "https://www.semanticscholar.org/author/Hanna-Hajishirzi/2264251662",
  "Ross Girshick":
    "https://www.semanticscholar.org/author/Ross-Girshick/2268775608",
};

/**
 * Adds a video to the documentation.
 *
 * @param props.aspectRatio the height / width of the video. Assumes 1 / 1 aspect ratio by default.
 * @param props.id the video id from Vimeo.
 */
export let Video = (props: {
  id: string | number;
  aspectRatio?: number;
  marginTop?: string;
  marginBottom?: string;
  autoplay?: 1 | 0;
  dropShadow?: boolean;
}) => (
  <div
    css={css`
      text-align: center;
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: calc(
        100% * ${props.aspectRatio === undefined ? 1 : props.aspectRatio}
      );
      background-color: ${color.gray5};
      margin: auto;
      margin-bottom: ${props.marginBottom === undefined
        ? "25px"
        : props.marginBottom};
      margin-top: ${props.marginTop};
      border-radius: 5px;
      filter: ${props.dropShadow !== false
        ? "drop-shadow(0px 0px 38px #e9e9e9)"
        : undefined};
      -webkit-filter: ${props.dropShadow !== false
        ? "drop-shadow(0px 0px 38px #e9e9e9)"
        : undefined};
    `}
  >
    <iframe
      src={`https://player.vimeo.com/video/${props.id}?autoplay=${
        props.autoplay !== undefined ? props.autoplay : 1
      }&autopause=0`}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      `}
    />
  </div>
);

const LANGUAGE_COLORS = {
  PyTorch: "bg-red-600",
  Unity: "bg-green-600",
  Python: "bg-orange-600",
  Colab: "bg-violet-600",
  React: "bg-blue-600",
};

function Code(props: {
  emoji?: string;
  type: string;
  owner: string;
  name: string;
  description: string;
  language: string;
}) {
  const [stargazersCount, setStargazersCount] = useState(undefined),
    [forksCount, setForksCount] = useState(undefined);

  useEffect(() => {
    const getCount = async () => {
      const res = await fetch(
        `https://api.github.com/repos/${props.owner}/${props.name}`
      );
      const resJson = await res.json();
      console.log(resJson);
      setStargazersCount(resJson.stargazers_count);
      setForksCount(resJson.forks_count);
    };
    getCount();
  }, [props.owner, props.name]);

  return (
    <div className="rounded-md border-gray-200 border-[1.5px] p-3 bg-white">
      <div>
        <GoRepo className="inline-block align-middle" />{" "}
        <a
          className="inline-block align-middle text-sm ml-1"
          href={`//github.com/${props.owner}/${props.name}`}
          target="_blank"
        >
          {props.owner}
          <span className="font-semibold">/{props.name}</span>
        </a>
        <div className="inline-block ml-2 rounded-full border-gray-300 border-[1px] text-xs px-[6px] text-gray-600">
          {props.type}
        </div>
      </div>
      <div className="text-xs mt-3 text-gray-600">
        {props.emoji ? <Emoji emoji={props.emoji} size={14} /> : undefined}{" "}
        {props.description}
      </div>
      <div className="mt-3 text-xs">
        <div
          className={`rounded-full w-3 h-3 ${
            LANGUAGE_COLORS[props.language]
          } inline-block align-middle`}
        />
        <div className="inline-block ml-1 text-gray-600 align-middle w-12">
          {props.language}
        </div>
        <a
          href={`//github.com/${props.owner}/${props.name}/stargazers`}
          target="_blank"
          className="hover:underline !text-gray-600 hover:!text-ai2blue-500"
        >
          <div className="ml-4 inline-block align-middle text-base">
            <AiOutlineStar />
          </div>
          <div className="inline-block ml-[2px] align-middle">
            {stargazersCount}
          </div>
        </a>
        <a
          href={`//github.com/${props.owner}/${props.name}/network/members`}
          target="_blank"
          className="hover:underline !text-gray-600 hover:!text-ai2blue-500"
        >
          <div className="ml-4 inline-block align-middle text-base">
            <VscRepoForked />
          </div>
          <div className="inline-block ml-[2px] align-middle">{forksCount}</div>
        </a>
      </div>
    </div>
  );
}

function News(props: {
  description: string | React.ReactNode;
  date: string;
  img: React.ReactNode | StaticImage;
}) {
  return (
    <div>
      {props.img}
      <div className="mt-2 px-2 text-sm">{props.description}</div>
      <div className="text-gray-400 px-2 text-sm absolute bottom-3">
        {props.date}
      </div>
    </div>
  );
}

function Section(props: { title: string; children: any }) {
  return (
    <div
      css={css`
        margin-bottom: 50px;
      `}
    >
      <h3
        css={css`
          margin-top: 0px;
          position: relative;
          z-index: 99 !important;
        `}
      >
        {props.title}
      </h3>
      <div>{props.children}</div>
    </div>
  );
}

function Icon(props: { url: string; icon: React.ReactNode }) {
  return (
    <a
      href={props.url}
      target="_blank"
      css={css`
        margin-left: 8px;
        margin-right: 8px;
        transition-duration: 0.3s;
        &:hover {
          opacity: 0.85;
        }
      `}
    >
      {props.icon}
    </a>
  );
}

function Circle(props: { r?: number; fill?: string }) {
  const r = props.r ? props.r : 1.75,
    size = 10;
  return (
    <svg height={size} width={size} className="inline-block">
      <circle
        cx={size / 2}
        cy={size / 2}
        r={r}
        fill={props.fill ? props.fill : color.gray7}
      />
    </svg>
  );
}

function PaperIcon(props: { image: string; link: string }) {
  return (
    <a href={props.link} target="_blank">
      <img
        src={props.image}
        css={css`
          height: 20px;
          margin-left: 7px;
          margin-right: 7px;
          transition-duration: 0.15s;

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `}
      />
    </a>
  );
}

function Publication(props: {
  image: React.ReactNode;
  title: string | React.ReactNode;
  authors: string[];
  venue: string | React.ReactNode;
  tldr: string | React.ReactNode;
  highlighted?: boolean;

  arxivId: string;
  s2?: string;
  github?: string;
  website?: string;

  citations: number;

  videoId?: string | number;
  videoAspect?: number;
  citationLink: string;
}) {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <div
      css={css`
        position: relative;
        margin-bottom: 80px;
      `}
    >
      {/* <div
        css={css`
          position: absolute;
          text-align: right;
          margin-left: -50px;
          font-size: 16px;
          color: ${color.gray6};
        `}
      >
        [{props.i}]
      </div> */}
      <div
        css={css`
          width: 35%;
          display: inline-block;
          vertical-align: top;
        `}
      >
        {props.image}
      </div>
      <div
        css={css`
          width: 65%;
          padding-left: 30px;
          display: inline-block;
          vertical-align: top;
        `}
      >
        <h4
          css={css`
            margin-top: 0px;
            margin-bottom: 15px;
          `}
        >
          {props.title}
        </h4>
        <p
          css={css`
            margin-top: -10px;
            font-size: 13.5px;
            color: ${color.gray9};
            margin-bottom: 18px;
          `}
        >
          {props.authors.map((author: string, i: number) => {
            const addStar = author.endsWith("*");
            if (addStar) {
              author = author.slice(0, -1);
            }
            let formattedAuthor: string | React.ReactNode = author;
            if (author.includes("Matt Deitke")) {
              formattedAuthor = (
                <span
                  css={css`
                    font-weight: 600;
                    text-decoration: none;
                  `}
                >
                  {author}
                </span>
              );
            }
            if (i === 0) {
              return (
                <span key={i}>
                  <a href={authorMap[author]} target="_blank">
                    <span
                      css={css`
                        text-decoration: none;
                      `}
                    >
                      {formattedAuthor}
                    </span>
                  </a>
                  {addStar ? "*" : ""}
                </span>
              );
            } else {
              return (
                <span key={i}>
                  ,{" "}
                  <a href={authorMap[author]} target="_blank">
                    <span
                      css={css`
                        text-decoration: none;
                      `}
                    >
                      {formattedAuthor}
                    </span>
                  </a>
                  <span
                    css={css`
                      color: ${color.light.blue8};
                    `}
                  >
                    {addStar ? "*" : ""}
                  </span>
                </span>
              );
            }
          })}
        </p>
        <div
          css={css`
            font-weight: 500;
            font-size: 13.5px;
            margin-top: -10px;
          `}
        >
          {props.venue}
        </div>
        <div
          css={css`
            font-size: 13.5px;
            margin-top: 8px;
            margin-bottom: 15px;
          `}
        >
          <div
            css={css`
              display: inline-block;
              font-weight: 600;
              background-color: ${color.gray1};
              font-size: 13.5px;
              color: ${color.gray11};
              padding-left: 6px;
              padding-right: 6px;
              border-radius: 5px;
              box-shadow: 0px 0px 3px 0px ${color.gray5};
            `}
          >
            TLDR
          </div>{" "}
          {props.tldr}
        </div>
        <div
          css={css`
            margin-top: 5px;
            font-size: 12.5px;
            font-weight: 500;
          `}
        >
          <div
            css={css`
              display: inline-block;
              cursor: pointer;
            `}
          >
            <a href={props.s2} target="_blank">
              <img
                src={CITATIONS_ICON}
                css={css`
                  height: 11px;
                  vertical-align: middle;
                  margin-top: -2px;
                  margin-right: 4px;
                  display: inline-block;
                `}
              />
              <span
                css={css`
                  line-height: 10px;
                  color: #d77a27;
                `}
              >
                {props.citations}
              </span>
            </a>
          </div>
          <a
            href={`//arxiv.org/pdf/${props.arxivId}.pdf`}
            target="_blank"
            css={css`
              margin-left: 8px;
              &:hover {
                text-decoration: none !important;
              }
            `}
          >
            <div
              css={css`
                display: inline-block;
                border-radius: 25px;
                padding-left: 8px;
                padding-right: 8px;
                color: ${color.light.orange7};
                padding-bottom: 1.5px;
                line-height: 16px;
                border: 1px solid ${color.gray6};
                transition-duration: 0.3s;
                background-color: white;
                &:hover {
                  border: 1px solid ${color.gray7};
                }
              `}
            >
              <span
                css={css`
                  font-weight: 500;
                  color: ${color.light.orange7};
                  font-size: 12px;
                `}
              >
                PDF
              </span>
            </div>
          </a>
          <div
            css={css`
              display: inline-block;
              margin-left: 7px;
            `}
          >
            <Circle r={1.25} fill={color.gray6} />
          </div>
          {props.s2 ? (
            <div
              css={css`
                display: inline-block;
                border-radius: 25px;
                padding-left: 9px;
                padding-right: 9px;
                padding-bottom: 2.5px;
                transition-duration: 0.3s;
              `}
            >
              <a href={props.s2} target="_blank">
                <img
                  src={S2_LONG_ICON}
                  css={css`
                    height: 13px;
                    margin-right: 4px;
                    display: inline-block;
                  `}
                />
                <span
                  css={css`
                    font-size: 12px;
                    color: ${color.light.blue8};
                  `}
                >
                  <span
                    css={css`
                      font-weight: 500;
                    `}
                  >
                    Semantic
                  </span>{" "}
                  <span
                    css={css`
                      font-weight: 600;
                    `}
                  >
                    Scholar
                  </span>
                </span>
              </a>
            </div>
          ) : (
            <></>
          )}
          {props.website ? (
            <div
              css={css`
                display: inline-block;
                border-radius: 25px;
                padding-left: 9px;
                padding-right: 9px;
                padding-bottom: 2.5px;
                transition-duration: 0.3s;
              `}
            >
              <a href={props.website} target="_blank">
                <img
                  src="/icons/home-icon.png"
                  css={css`
                    height: 17px;
                    margin-right: 4px;
                    display: inline-block;
                    margin-top: -1.5px;
                  `}
                />
                <span
                  css={css`
                    font-size: 12px;
                    color: ${color.light.orange8};
                    font-weight: 600;
                  `}
                >
                  Website
                </span>
              </a>
            </div>
          ) : (
            <></>
          )}
          {props.github ? (
            <div
              css={css`
                display: inline-block;
                margin-left: 2px;
                border-radius: 25px;
                padding-left: 9px;
                padding-right: 9px;
                padding-bottom: 2.5px;
                transition-duration: 0.3s;
              `}
            >
              <a href={props.github} target="_blank">
                <img
                  src={GH_DARK_ICON}
                  css={css`
                    height: 13px;
                    vertical-align: middle;
                    margin-top: -1.5px;
                    margin-right: 4px;
                    display: inline-block;
                  `}
                />
                <span
                  css={css`
                    font-weight: 600;
                    font-size: 12px;
                    color: ${color.gray10};
                  `}
                >
                  GitHub
                </span>
              </a>
            </div>
          ) : (
            <></>
          )}
          {props.arxivId ? (
            <div
              css={css`
                display: inline-block;
                border-radius: 25px;
                margin-left: 2px;
                padding-left: 9px;
                padding-right: 9px;
                padding-bottom: 2.5px;
                transition-duration: 0.3s;
                &:hover {
                }
              `}
            >
              <a href={`//arxiv.org/abs/${props.arxivId}`} target="_blank">
                <img
                  src={PDF_ICON}
                  css={css`
                    height: 13px;
                    vertical-align: middle;
                    margin-top: -1.5px;
                    margin-right: 4px;
                    display: inline-block;
                  `}
                />
                <span
                  css={css`
                    font-weight: 600;
                    font-size: 12px;
                    color: ${color.light.red9};
                  `}
                >
                  arXiv
                </span>
              </a>
            </div>
          ) : (
            <></>
          )}
          {props.videoId ? (
            <div
              css={css`
                display: inline-block;
                margin-left: 2px;
                border-radius: 25px;
                padding-left: 9px;
                padding-right: 9px;
                padding-bottom: 2.5px;
                transition-duration: 0.3s;
                opacity: ${showVideo ? 0.7 : 1};
              `}
              onClick={() => setShowVideo((prev) => !prev)}
            >
              <a>
                <Emoji emoji="movie_camera" size={15} />
                <span
                  css={css`
                    font-weight: 600;
                    font-size: 12px;
                    margin-left: 4px;
                    color: ${color.gray10};
                  `}
                >
                  Video
                </span>
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {showVideo && props.videoId ? (
        <div
          css={css`
            margin-top: 25px;
          `}
        >
          <Video
            id={props.videoId}
            aspectRatio={props.videoAspect}
            dropShadow={false}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function NewsSection() {
  const [newsStart, setNewsStart] = useState(0),
    [showEnd, setShowEnd] = useState(true),
    [showStart, setShowStart] = useState(false),
    [resetState, setResetState] = useState(false);

  // create a ref of the slider
  const sliderRef = useRef(null);

  const newsArticles = [
    <News
      img={
        <StaticImage
          src="../images/city.jpg"
          alt="A futuristic cityscape"
          placeholder="blurred"
        />
      }
      date="Dec, 2024"
      description={
        <>
          Thrilled to announce that I'm co-founding{" "}
          <a href="https://vercept.com" target="_blank">
            <b className="font-medium">Vercept</b>
          </a>
          ! So excited to build what's next. 🚀
        </>
      }
    />,
    <News
      img={
        <StaticImage
          src="../images/neurips6.png"
          alt="NeurIPS Outstanding Paper Award"
          placeholder="blurred"
        />
      }
      date="Nov, 2022"
      description={
        <>
          <a href="//procthor.allenai.org/" target="_blank">
            <Emoji emoji="house_buildings" size={16} />
            <b className="font-medium">ProcTHOR</b>
          </a>{" "}
          won the{" "}
          <a
            href="https://blog.neurips.cc/2022/11/21/announcing-the-neurips-2022-awards/"
            target="_blank"
          >
            <b className="font-medium">Outstanding Paper Award</b>{" "}
          </a>
          at NeurIPS 2022!
        </>
      }
    />,
    <News
      date="Dec, 2022"
      img={
        <StaticImage
          src="../images/objaverse2.jpg"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          Excited to announce{" "}
          <b className="font-medium">
            <a href="https://objaverse.allenai.org" target="_blank">
              Objaverse
            </a>
          </b>
          , a massive dataset of 3D objects with broad applications across AI.
        </>
      }
    />,
    <News
      date="Apr, 2023"
      img={
        <StaticImage
          src="../images/path.jpg"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          Thrilled to continue at UW for my Ph.D. working with Ali Farhadi and
          at AI2.
        </>
      }
    />,
    <News
      date="Jun, 2023"
      img={
        <StaticImage
          src="../images/reviewer-3.jpg"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          Received an{" "}
          <a
            href="https://cvpr2023.thecvf.com/Conferences/2023/OutstandingReviewers"
            target="_blank"
          >
            Outstanding Reviewer Award
          </a>{" "}
          from CVPR 2023!
        </>
      }
    />,
    <News
      date="Jun, 2023"
      img={
        <StaticImage
          src="../images/talk-2.jpg"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          Giving invited talks at{" "}
          <a
            href="https://sites.google.com/usc.edu/egg-rss-2023"
            target="_blank"
          >
            RSS 2023
          </a>
          ,{" "}
          <a href="https://ai3dcc.github.io/" target="_blank">
            ICCV 2023
          </a>
          , and{" "}
          <a href="https://www.shlab.org.cn/" target="_blank">
            Shanghai AI Lab
          </a>
          !
        </>
      }
    />,
    <News
      date="Jan, 2022"
      img={
        <StaticImage
          src="../images/husky-reading.png"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          The 2nd edition of{" "}
          <a href="//szeliski.org/" target="_blank">
            Rick Szeliski's
          </a>{" "}
          <b className="font-medium">
            <a href="//szeliski.org/Book/" target="_blank">
              Computer Vision textbook
            </a>
          </b>{" "}
          was published! Ecstatic to have contributed!
        </>
      }
    />,
    <News
      date="Mar, 2023"
      img={
        <StaticImage
          src="../images/grad.jpg"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          Grateful to have received Ph.D. offers at CMU, MIT, Oxford, Stanford,
          UC Berkeley, and UW.
        </>
      }
    />,
    <News
      date="Jun, 2022"
      img={
        <StaticImage
          src="../images/raven.png"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          Giving an <b className="font-medium">invited talk</b> at UW's Vision
          Lunch titled, "Scaling Embodied AI with{" "}
          <a href="//procthor.allenai.org/" target="_blank">
            <Emoji emoji="house_buildings" size={16} />
            ProcTHOR
          </a>
          : Where We Are and What's Next."
        </>
      }
    />,
    <News
      img={
        <StaticImage
          src="../images/procthor-dalle-5.png"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      date="Jun, 2022"
      description={
        <>
          Extremely excited to release{" "}
          <a href="//procthor.allenai.org/" target="_blank">
            <b className="font-medium">ProcTHOR</b>
          </a>
          ! Using procedural generation to scale up the diversity of data leads
          to remarkable generalization.
        </>
      }
    />,
    <News
      img={
        <StaticImage
          src="../images/retrospectives.png"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      date="Oct, 2022"
      description={
        <>
          Excited to release a{" "}
          <a
            href="https://arxiv.org/abs/2210.06849"
            target="_blank"
            className="font-medium"
          >
            retrospectives on the Embodied AI workshops
          </a>
          ! We discuss common approaches, its scope, and future directions.
        </>
      }
    />,
    <News
      date="Jun, 2022"
      img={
        <StaticImage
          src="../images/cvpr-2022-2.png"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          Co-Organizing the{" "}
          <b className="font-medium">
            <a href="//embodied-ai.org/">Embodied AI Workshop</a>
          </b>{" "}
          and the{" "}
          <a href="//ai2thor.allenai.org/rearrangement/" target="_blank">
            AI2-THOR Rearrangement Challenge
          </a>{" "}
          at CVPR 2022 in New Orleans.
        </>
      }
    />,

    <News
      date="Aug, 2022"
      img={
        <StaticImage
          src="../images/ai2thor-cover.png"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          We released an updated revision of the{" "}
          <a href="//arxiv.org/abs/1712.05474" target="_blank">
            <b className="font-medium">AI2-THOR paper</b>
          </a>{" "}
          covering its impact and new features!
        </>
      }
    />,
    <News
      date="Aug, 2022"
      img={
        <StaticImage
          src="../images/reviewer-2.png"
          alt="Avatar"
          placeholder="blurred"
        />
      }
      description={
        <>
          Reviewing at
          <br />-{" "}
          <a href="//cvpr2023.thecvf.com/" target="_blank">
            NeurIPS 2023
          </a>
          <br />-{" "}
          <a href="//cvpr2023.thecvf.com/" target="_blank">
            CVPR 2023
          </a>
          <br />-{" "}
          <a href="//iclr.cc/" target="_blank">
            ICLR 2023
          </a>
        </>
      }
    />,
  ];

  // use effect when newsStart updates
  useEffect(() => {
    // update the slider scrollLeft position to
    const newScrollLeftPos = newsStart * sliderRef.current.clientWidth * 0.251;
    // animate the scroll to the new position over time
    sliderRef.current.scroll({
      left: newScrollLeftPos,
      behavior: "smooth",
    });
  }, [newsStart, resetState]);

  return (
    <div className="relative">
      {/* more to the left */}
      <div
        onClick={() => {
          setResetState((prev) => !prev);
          setNewsStart((prev) => Math.max(0, prev - 4));
        }}
        className={
          "absolute left-0 text-xl bg-white text-gray-500 rounded-full" +
          " h-10 w-10 text-center border-[1px] border-gray-300" +
          " top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10" +
          " hover:cursor-pointer hover:text-gray-800 hover:drop-shadow-md" +
          " duration-150"
        }
        style={{
          opacity: showStart ? "1" : "0",
        }}
      >
        <div className="relative h-9">
          <AiOutlineLeft className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 " />
        </div>
      </div>

      <div
        className="w-[100%] overflow-x-auto no-scrollbar"
        ref={sliderRef}
        onScroll={(e: any) => {
          // if this section's scroll is maxed at the right
          const onRight =
              e.target.scrollLeft + e.target.clientWidth + 10 >=
              e.target.scrollWidth,
            onLeft = e.target.scrollLeft <= 0;
          if (onRight) {
            if (showEnd) {
              setShowEnd(false);
              setNewsStart(newsArticles.length - 4);
            }
          } else if (!showEnd) {
            setShowEnd(true);
          }
          if (onLeft) {
            if (showStart) {
              setShowStart(false);
              setNewsStart(0);
            }
          } else if (!showStart) {
            setShowStart(true);
          }
        }}
      >
        <div
          className={`grid grid-cols-8 gap-x-[7px] gap-y-12 relative auto-rows-fr duration-300`}
          style={{
            width: `${newsArticles.length * (100 / 5) + 0.5}%`,
            gridTemplateColumns: `repeat(${newsArticles.length}, minmax(0, 1fr))`,
          }}
        >
          {newsArticles.map((article, i) => (
            <div
              key={i}
              className="border-[1.5px] rounded-md overflow-hidden relative pb-10 bg-white"
            >
              {article}
            </div>
          ))}
        </div>
      </div>
      {/* more to the right */}
      <div
        onClick={() => {
          setResetState((prev) => !prev);
          setNewsStart((prev) => Math.min(prev + 4, newsArticles.length - 4));
        }}
        className={
          "absolute right-0 text-xl bg-white text-gray-500 rounded-full" +
          " h-10 w-10 text-center border-[1px] border-gray-300" +
          " top-1/2 transform translate-x-1/2 -translate-y-1/2" +
          " hover:cursor-pointer hover:text-gray-800 hover:drop-shadow-md" +
          " duration-150"
        }
        style={{
          opacity: showEnd ? "1" : "0",
        }}
      >
        <div className="relative h-9">
          <AiOutlineRight className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 " />
        </div>
      </div>
    </div>
  );
}

export default function Home() {
  const [citations, setCitations] = useState({});
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const fetchData = async () => {
    const response = await fetch(
      "https://api.semanticscholar.org/graph/v1/paper/batch?fields=citationCount,title",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ids: [
            "1b90e9e9734bed6b379ae87d688cb3b887baf597",
            "1b31dbf44e68b698120552366df03e6e35a1e428",
            "7f3fe0c2a941aaf1b41a2641c9e5571b79cec499",
            "d7bca917b753b996074203cc77ba45935b7c62d7",
            "30d0a5a5f8e776c844a37afe3b1ace0b21b24859",
            "89c8aad71433f7638d2e2c009e1ea20e039f832d",
            "6500df35e30461d857103b3e3fa72b2913049a56",
            "98789b46f7be983300a0e93e1c53bab56b36efd1",
            "3462eb6ba2b358ed95afca845e57f22cd0d79a8e",
          ],
        }),
      }
    );

    const data = await response.json();
    console.log(JSON.stringify(data, null, 2));

    // map from paperId to citationCount
    const citations = {};
    data.forEach((paper) => {
      citations[paper.paperId] = paper.citationCount;
    });
    setCitations(citations);
  };

  console.log(citations);

  return (
    <>
      <Helmet>
        <title>Matt Deitke</title>
        <meta name="viewport" content=""></meta>
      </Helmet>
      <div>
        <div
          className="gap-x-16 mx-auto mt-12 grid grid-cols-[280px_1fr] w-[1280px] px-8"
          css={css`
            /* max-width: 950px; */
            /* grid-template-columns: 300px 1fr; */
            /* width: 95%; */
          `}
        >
          <div className="sticky top-16 h-[100vh]">
            <StaticImage
              src="../images/MattDeitke.jpg"
              alt="Matt Deitke"
              placeholder="blurred"
              css={css`
                border-radius: 5px;
                box-shadow: 0 0 38px 0 rgba(0, 0, 0, 0.2);
              `}
            />
            <div
              css={css`
                font-size: 15px;
                margin-top: 15px;
              `}
            >
              <div className="font-medium text-2xl">Matt Deitke</div>
              <div>
                AI Researcher at{" "}
                <a href="https://vercept.com" target="_blank">
                  Vercept
                </a>
              </div>
              <div className="invert-[0.6] mt-3 ml-[-5px]">
                <Icon
                  url="//github.com/mattdeitke"
                  icon={
                    <img
                      src={GH_ICON}
                      css={css`
                        width: 21px;
                        display: inline-block;
                      `}
                    />
                  }
                />
                <Icon
                  url="//semanticscholar.org/author/Matt-Deitke/1632916259"
                  icon={
                    <img
                      src={S2_ICON}
                      css={css`
                        width: 26px;
                        display: inline-block;
                      `}
                    />
                  }
                />
                <Icon
                  url="//scholar.google.com/citations?user=k4VxCcYAAAAJ"
                  icon={
                    <img
                      src={GS_ICON}
                      css={css`
                        display: inline-block;
                        width: 19px;
                      `}
                    />
                  }
                />
                <Icon
                  url="//twitter.com/mattdeitke"
                  icon={
                    <img
                      src={TWITTER_ICON}
                      css={css`
                        width: 22px;
                        display: inline-block;
                      `}
                    />
                  }
                />
              </div>
              {/* <div className="mt-1">
              <div className="w-10 h-10 rounded-md border-gray-200 border-2 inline-flex justify-center mr-1">
                <img src={AI2_LOGO} className="w-10/12" />
              </div>
              <div className="w-10 h-10 rounded-md border-gray-200 border-2 inline-flex justify-center mr-1">
                <img src={UW_CSE_LOGO} className="w-10/12" />
              </div>
            </div> */}
              <hr />
              <div>
                {[
                  [<AiOutlineMail />, <>matt@vercept.com</>],
                  [<GoLocation />, "Seattle, WA"],
                  [
                    <IoNewspaperOutline />,
                    <a href="/CV.pdf" target="_blank">
                      CV
                    </a>,
                  ],
                ].map((icon, i) => (
                  <div key={i} className="mt-1">
                    <div className="inline-block w-6 text-lg align-middle">
                      {icon[0]}
                    </div>
                    <div className="inline-block align-middle text-sm">
                      {icon[1]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <h1
              css={css`
                font-size: 40px;
                display: inline-block;
                margin-top: 0px;
              `}
            >
              Matt Deitke
            </h1>
            <div>
              <p>
                Hi! I'm an AI researcher and co-founder of{" "}
                <a href="https://vercept.com" target="_blank">
                  Vercept
                </a>{" "}
                (in stealth).
              </p>
              <p>
                Previously, I worked with the{" "}
                <a href="//prior.allenai.org" target="_blank">
                  PRIOR
                </a>{" "}
                team at the{" "}
                <a href="//allenai.org" target="_blank">
                  Allen Institute for AI
                </a>
                , and was a Ph.D. student at the{" "}
                <a href="//cs.washington.edu" target="_blank">
                  Allen School
                </a>{" "}
                in the{" "}
                <a href="//washington.edu">University of Washington, Seattle</a>
                . I was fortunate to be advised by{" "}
                <a href="https://homes.cs.washington.edu/~ali/" target="_blank">
                  Ali Farhadi
                </a>{" "}
                in the{" "}
                <a href="https://raivn.cs.washington.edu/" target="_blank">
                  RAIVN lab
                </a>
                , and have had the pleasure of collaborating with{" "}
                <a href="https://anikem.github.io/" target="_blank">
                  Ani Kembhavi
                </a>
                ,{" "}
                <a href="https://roozbehm.info/" target="_blank">
                  Roozbeh Mottaghi
                </a>
                ,{" "}
                <a href="https://people.csail.mit.edu/ludwigs/" target="_blank">
                  Ludwig Schmidt
                </a>
                , and{" "}
                <a
                  href="https://szeliski.org/RichardSzeliski.htm"
                  target="_blank"
                >
                  Rick Szeliski
                </a>
                . My research interests are in computer vision, deep learning,
                and embodied AI. I am interested in building AI systems that are
                broadly useful and robust. I led the development
                of{" "}
                <a href="https://molmo.allenai.org/" target="_blank">
                  Molmo
                </a>
                ,{" "}
                <a href="https://procthor.allenai.org/" target="_blank">
                  ProcTHOR
                </a>
                ,{" "}
                <a href="https://objaverse.allenai.org/" target="_blank">
                  Objaverse
                </a>
                , and <a href="https://phone2proc.allenai.org">Phone2Proc</a>.
              </p>
              <p>
                Since 2019, I have been conducting research at the Allen
                Institute for AI while completing my undergrad at the University
                of Washington, Seattle. Before that, I grew up near Chicago and
                spent several teenage years working on computer graphics,
                interface design, and visualization in the Department of
                Athletics at The Ohio State University, the University of
                Cincinnati, and a variety of other organizations. Later in high
                school, I studied machine learning and deep learning at Georgia
                Tech.
              </p>
            </div>
            <div className="mt-12" />
            <Section title="News">
              {/* <div className="italic mb-5">Images generated with DALL-E.</div> */}
              <NewsSection />
            </Section>
            {/* <Section title="Preprints">
              <div className="h-3" />
            </Section> */}
            <Section title="Publications">
              <div className="h-3" />
              <Publication
                citationLink="//arxiv.org/abs/2409.17146"
                highlighted={true}
                arxivId="2409.17146"
                image={
                  <div className="rounded-lg overflow-hidden drop-shadow-sm">
                    <StaticImage
                      src="../images/thumbnail.jpg"
                      alt="An image of a man holding a phone taking a pictuer of a group of people at a table."
                      placeholder="blurred"
                    />
                  </div>
                }
                citations={
                  citations["3462eb6ba2b358ed95afca845e57f22cd0d79a8e"] || ""
                }
                github="//github.com/allenai/molmo"
                title="Molmo and PixMo: Open Weights and Open Data for State-of-the-Art Vision-Language Models"
                website="https://molmo.allenai.org/"
                authors={[
                  "Matt Deitke*",
                  "Christopher Clark*",
                  "Sangho Lee",
                  "Rohun Tripathi",
                  "Yue Yang",
                  "Jae Sung Park",
                  "Mohammadreza Salehi",
                  "Niklas Muennighoff",
                  "Kyle Lo",
                  "Luca Soldaini",
                  "Jiasen Lu",
                  "Taira Anderson",
                  "Erin Bransom",
                  "Kiana Ehsani",
                  "Huong Ngo",
                  "YenSung Chen",
                  "Ajay Patel",
                  "Mark Yatskar",
                  "Chris Callison-Burch",
                  "Andrew Head",
                  "Rose Hendrix",
                  "Favyen Bastani",
                  "Eli VanderBilt",
                  "Nathan Lambert",
                  "Yvonne Chou",
                  "Arnavi Chheda",
                  "Jenna Sparks",
                  "Sam Skjonsberg",
                  "Michael Schmitz",
                  "Aaron Sarnat",
                  "Byron Bischoff",
                  "Pete Walsh",
                  "Chris Newell",
                  "Piper Wolters",
                  "Tanmay Gupta",
                  "Kuo-Hao Zeng",
                  "Jon Borchardt",
                  "Dirk Groeneveld",
                  "Crystal Nam",
                  "Sophie Lebrecht",
                  "Caitlin Wittlif",
                  "Carissa Schoenick",
                  "Oscar Michel",
                  "Ranjay Krishna",
                  "Luca Weihs",
                  "Noah A. Smith",
                  "Hannaneh Hajishirzi",
                  "Ross Girshick",
                  "Ali Farhadi",
                  "Aniruddha Kembhavi",
                ]}
                venue={<>ArXiv 2024</>}
                tldr={
                  <>
                    We release Molmo, a new family of state-of-the-art open
                    vision-language models built entirely from scratch,
                    featuring our novel PixMo datasets that include detailed
                    image captions, Q&A pairs, and 2D pointing data. Our 72B
                    model outperforms most proprietary models like Claude 3.5
                    Sonnet and Gemini 1.5, ranking second only to GPT-4 in both
                    academic benchmarks and human evaluations.
                  </>
                }
                s2="//api.semanticscholar.org/CorpusID:272880654"
              />
              <Publication
                citationLink="//arxiv.org/abs/2307.05663"
                highlighted={true}
                arxivId="2307.05663"
                image={
                  <div className="rounded-lg overflow-hidden drop-shadow-sm">
                    <StaticImage
                      src="../images/objaverse-xl.jpg"
                      alt="An image of a bunch of 3D objects scattered in a scene"
                      placeholder="blurred"
                    />
                  </div>
                }
                citations={
                  citations["1b90e9e9734bed6b379ae87d688cb3b887baf597"] || ""
                }
                github="//github.com/allenai/objaverse-xl"
                title="Objaverse-XL: A Universe of 10M+ 3D Objects"
                website="https://objaverse.allenai.org/"
                authors={[
                  "Matt Deitke",
                  "Ruoshi Liu",
                  "Matthew Wallingford",
                  "Huong Ngo",
                  "Oscar Michel",
                  "Aditya Kusupati",
                  "Alan Fan",
                  "Christian Laforte",
                  "Vikram Voleti",
                  "Samir Yitzhak Gadre",
                  "Eli VanderBilt",
                  "Aniruddha Kembhavi",
                  "Carl Vondrick",
                  "Georgia Gkioxari",
                  "Kiana Ehsani",
                  "Ludwig Schmidt*",
                  "Ali Farhadi*",
                ]}
                venue={<>NeurIPS 2023</>}
                tldr={
                  <>
                    We introduce Objaverse-XL, an open dataset of over 10
                    million 3D objects. With it, we train Zero123-XL, a
                    foundation model for 3D, observing incredible 3D
                    generalization abilities. With the Zero123-XL base model, we
                    can then perform image-to-3D and text-to-3D.
                  </>
                }
                s2="//api.semanticscholar.org/CorpusID:259836993"
              />
              <Publication
                citationLink="//arxiv.org/abs/2212.08051"
                highlighted={true}
                arxivId="2212.08051"
                image={
                  <StaticImage
                    src="../images/objaverse-cover3.png"
                    alt="Objaverse"
                    placeholder="blurred"
                    // css={css`
                    //   border-radius: 5px;
                    //   -webkit-filter: drop-shadow(0px 0px 38px #d9d9d966);
                    //   filter: drop-shadow(0px 0px 38px #d9d9d966);
                    // `}
                  />
                }
                citations={
                  citations["1b31dbf44e68b698120552366df03e6e35a1e428"] || ""
                }
                title="Objaverse: A Universe of Annotated 3D Objects"
                website="https://objaverse.allenai.org/objaverse-1.0"
                authors={[
                  "Matt Deitke",
                  "Dustin Schwenk",
                  "Jordi Salvador",
                  "Luca Weihs",
                  "Oscar Michel",
                  "Eli VanderBilt",
                  "Ludwig Schmidt",
                  "Kiana Ehsani",
                  "Aniruddha Kembhavi",
                  "Ali Farhadi",
                ]}
                venue={<>CVPR 2023</>}
                tldr={
                  <>
                    Objaverse is a massive dataset of objects with 800K+ (and
                    growing) 3D models with descriptive captions, tags, and
                    animations. We demonstrate it's potential by training
                    generative models, improving 2D instance segmentation,
                    training open-vocabulary object navigation models, and
                    creating a benchmark for testing the robustness of vision
                    models.
                  </>
                }
                s2="//api.semanticscholar.org/CorpusID:254685588"
              />
              <Publication
                citationLink="//arxiv.org/abs/2212.04819"
                citations={
                  citations["7f3fe0c2a941aaf1b41a2641c9e5571b79cec499"] || ""
                }
                highlighted={true}
                arxivId="2212.04819"
                image={
                  <StaticImage
                    src="../images/Phone2Proc6.jpg"
                    alt="Phone2Proc"
                    placeholder="blurred"
                  />
                }
                title="Phone2Proc: Bringing Robust Robots Into Our Chaotic World"
                website="https://allenai.org/project/phone2proc"
                authors={[
                  "Matt Deitke*",
                  "Rose Hendrix*",
                  "Luca Weihs",
                  "Ali Farhadi",
                  "Kiana Ehsani",
                  "Aniruddha Kembhavi",
                ]}
                venue={<>CVPR 2023</>}
                tldr={
                  <>
                    From a 10-minute iPhone scan of any environment, we
                    generated simulated training scenes that semantically match
                    that environment. Training a robot to perform ObjectNav in
                    these scenes dramatically improves sim-to-real performance
                    from 35% to 71% and results in an agent that is remarkably
                    robust to human movement, lighting variations, added
                    clutter, and rearranged objects.
                  </>
                }
                s2="//api.semanticscholar.org/CorpusID:254536045"
              />
              {/* </Section>
            <Section title="Publications"> */}
              <Publication
                citationLink="//api.semanticscholar.org/CorpusID:249642405"
                citations={
                  citations["d7bca917b753b996074203cc77ba45935b7c62d7"] || ""
                }
                highlighted={true}
                arxivId="2206.06994"
                image={
                  <StaticImage
                    src="../images/procthor-cover.jpeg"
                    alt="ProcTHOR"
                    placeholder="blurred"
                    css={css`
                      border-radius: 5px;
                      -webkit-filter: drop-shadow(0px 0px 38px #d9d9d9);
                      filter: drop-shadow(0px 0px 38px #d9d9d9);
                    `}
                  />
                }
                title="🏘️ ProcTHOR: Large-Scale Embodied AI Using Procedural Generation"
                website="https://procthor.allenai.org/"
                authors={[
                  "Matt Deitke",
                  "Eli VanderBilt",
                  "Alvaro Herrasti",
                  "Luca Weihs",
                  "Jordi Salvador",
                  "Kiana Ehsani",
                  "Winson Han",
                  "Eric Kolve",
                  "Ali Farhadi",
                  "Aniruddha Kembhavi",
                  "Roozbeh Mottaghi",
                ]}
                venue={
                  <>
                    NeurIPS 2022 <Circle r={1.25} fill={color.gray7} />{" "}
                    <Emoji emoji="tada" size={14} />{" "}
                    <b
                      css={css`
                        color: ${color.light.gold9};
                      `}
                    >
                      Outstanding Paper Award
                    </b>
                  </>
                }
                tldr={
                  <>
                    We built a platform to procedurally generate realistic,
                    interactive, simulated 3D environments to dramatically scale
                    up the diversity and size of training data in Embodied AI.
                    We find that it helps significantly with performance on many
                    tasks.
                  </>
                }
                s2="//api.semanticscholar.org/CorpusID:249642405"
              />
              <Publication
                citationLink="//api.semanticscholar.org/CorpusID:252873591"
                citations={
                  citations["30d0a5a5f8e776c844a37afe3b1ace0b21b24859"] || ""
                }
                arxivId="2210.06849"
                s2="//api.semanticscholar.org/CorpusID:252873591"
                image={
                  <StaticImage
                    src="../images/retrospective.jpg"
                    alt="Features in AI2-THOR"
                    placeholder="blurred"
                    css={css`
                      border-radius: 5px;
                      -webkit-filter: drop-shadow(0px 0px 38px #d9d9d9);
                      filter: drop-shadow(0px 0px 38px #d9d9d9);
                    `}
                  />
                }
                title="Retrospectives on the Embodied AI Workshop"
                authors={[
                  "Matt Deitke",
                  "Dhruv Batra",
                  "Yonatan Bisk",
                  "Tommaso Campari",
                  "Angel X. Chang",
                  "Devendra Singh Chaplot",
                  "Changan Chen",
                  "Claudia P\u00e9rez D'Arpino",
                  "Kiana Ehsani",
                  "Ali Farhadi",
                  "Li Fei-Fei",
                  "Anthony Francis",
                  "Chuang Gan",
                  "Kristen Grauman",
                  "David Hall",
                  "Winson Han",
                  "Unnat Jain",
                  "Aniruddha Kembhavi",
                  "Jacob Krantz",
                  "Stefan Lee",
                  "Chengshu Li",
                  "Sagnik Majumder",
                  "Oleksandr Maksymets",
                  "Roberto Mart\u00edn-Mart\u00edn",
                  "Roozbeh Mottaghi",
                  "Sonia Raychaudhuri",
                  "Mike Roberts",
                  "Silvio Savarese",
                  "Manolis Savva",
                  "Mohit Shridhar",
                  "Niko S\u00fcnderhauf",
                  "Andrew Szot",
                  "Ben Talbot",
                  "Joshua B. Tenenbaum",
                  "Jesse Thomason",
                  "Alexander Toshev",
                  "Joanne Truong",
                  "Luca Weihs",
                  "Jiajun Wu",
                ]}
                venue={<>ArXiv 2022</>}
                tldr={
                  <>
                    We present a retrospective on the state of Embodied AI
                    research. Our analysis focuses on 13 challenges in visual
                    navigation, rearrangement, and embodied vision-and-language.
                    We discuss the scope of embodied AI research, performance of
                    state-of-the-art models, common modeling approaches, and
                    future directions.
                  </>
                }
              />
              <Publication
                citationLink="//api.semanticscholar.org/CorpusID:28328610"
                citations={
                  citations["89c8aad71433f7638d2e2c009e1ea20e039f832d"] || ""
                }
                arxivId="1712.05474"
                s2="//api.semanticscholar.org/CorpusID:28328610"
                github="//github.com/allenai/ai2thor"
                image={
                  <StaticImage
                    src="../images/thor-cover.png"
                    alt="Features in AI2-THOR"
                    placeholder="blurred"
                    css={css`
                      border-radius: 5px;
                      -webkit-filter: drop-shadow(0px 0px 38px #d9d9d9);
                      filter: drop-shadow(0px 0px 38px #d9d9d9);
                    `}
                  />
                }
                title="AI2-THOR: An Interactive 3D Environment for Visual AI"
                website="https://ai2thor.allenai.org/"
                authors={[
                  "Eric Kolve",
                  "Roozbeh Mottaghi",
                  "Winson Han",
                  "Eli VanderBilt",
                  "Luca Weihs",
                  "Alvaro Herrasti",
                  "Matt Deitke",
                  "Kiana Ehsani",
                  "Daniel Gordon",
                  "Yuke Zhu",
                  "Aniruddha Kembhavi",
                  "Abhinav Gupta",
                  "Ali Farhadi",
                ]}
                venue={<>ArXiv 2022</>}
                tldr={
                  <>
                    We introduce The House Of inteRactions (THOR), a framework
                    for visual AI research. AI2-THOR consists of near
                    photo-realistic 3D indoor scenes, where AI agents can
                    navigate in the scenes and interact with objects to perform
                    tasks. It has enabled research in many areas of AI.
                  </>
                }
              />
              <Publication
                citationLink="//scholar.google.com/citations?view_op=view_citation&hl=en&user=k4VxCcYAAAAJ&citation_for_view=k4VxCcYAAAAJ:u-x6o8ySG0sC"
                citations={
                  citations["6500df35e30461d857103b3e3fa72b2913049a56"] || ""
                }
                videoId={529589199}
                videoAspect={0.6186}
                arxivId="2103.16544"
                s2="//api.semanticscholar.org/CorpusID:232417634"
                github="//github.com/allenai/ai2thor-rearrangement"
                image={
                  <StaticImage
                    src="../images/rearrangement-cover.png"
                    alt="Room Rearrangement in AI2-THOR"
                    placeholder="blurred"
                    css={css`
                      border-radius: 5px;
                      -webkit-filter: drop-shadow(0px 0px 38px #d9d9d9);
                      filter: drop-shadow(0px 0px 38px #d9d9d9);
                    `}
                  />
                }
                title="Visual Room Rearrangement"
                authors={[
                  "Luca Weihs",
                  "Matt Deitke",
                  "Aniruddha Kembhavi",
                  "Roozbeh Mottaghi",
                ]}
                venue={
                  <>
                    CVPR 2021 <Circle r={1.25} fill={color.gray7} />{" "}
                    <Emoji emoji="tada" size={14} />{" "}
                    <span
                      css={css`
                        color: ${color.light.gold9};
                      `}
                    >
                      Oral Presentation
                    </span>
                  </>
                }
                tldr={
                  <>
                    We built a pre-training task where the agent's goal is to
                    interactively rearrange objects in a room from one state to
                    another. For instance, the agent may have to open the Fridge
                    and move the Lettuce to the CounterTop. Modern deep-RL
                    struggles.
                  </>
                }
              />
              <Publication
                citationLink="//scholar.google.com/citations?view_op=view_citation&hl=en&user=k4VxCcYAAAAJ&citation_for_view=k4VxCcYAAAAJ:u5HHmVD_uO8C"
                citations={
                  citations["98789b46f7be983300a0e93e1c53bab56b36efd1"] || ""
                }
                github="//github.com/allenai/ai2thor/"
                s2="//api.semanticscholar.org/CorpusID:215768690"
                arxivId="2004.06799"
                website="https://ai2thor.allenai.org/robothor/"
                image={
                  <StaticImage
                    src="../images/robothor.png"
                    alt="RoboTHOR"
                    placeholder="blurred"
                    css={css`
                      border-radius: 5px;
                      -webkit-filter: drop-shadow(0px 0px 38px #d9d9d9);
                      filter: drop-shadow(0px 0px 38px #d9d9d9);
                    `}
                  />
                }
                title={
                  <>RoboTHOR: An Open Simulation-to-Real Embodied AI Platform</>
                }
                authors={[
                  "Matt Deitke*",
                  "Winson Han*",
                  "Alvaro Herrasti*",
                  "Aniruddha Kembhavi*",
                  "Eric Kolve*",
                  "Roozbeh Mottaghi*",
                  "Jordi Salvador*",
                  "Dustin Schwenk*",
                  "Eli VanderBilt*",
                  "Matthew Wallingford*",
                  "Luca Weihs*",
                  "Mark Yatskar*",
                  "Ali Farhadi",
                ]}
                venue="CVPR 2020"
                tldr={
                  <>
                    We rent office buildings in Seattle and turn them into
                    apartment studios with many possible furniture and wall
                    layouts. Each apartment layout is then computationally
                    remodeled by hand to enable a simulated robot to interact
                    with it in video-game-like context. We study how well a
                    robot trained purely in the simulated environments can
                    transfer to reality.
                  </>
                }
              />
            </Section>
            <Section title="Software">
              <div>
                <div
                  css={css`
                    width: 65%;
                    display: inline-block;
                    vertical-align: top;
                  `}
                >
                  <Video autoplay={0} id="508999827" aspectRatio={9 / 16} />
                </div>
                <div
                  css={css`
                    width: 35%;
                    display: inline-block;
                    padding-left: 30px;
                    vertical-align: top;
                  `}
                >
                  <h3
                    css={css`
                      margin-top: 0px;
                    `}
                  >
                    AI2-THOR
                  </h3>
                  <p>
                    AI2-THOR consists of real and simulated environments for
                    interactive robot learning.
                  </p>
                  <a href="//ai2thor.allenai.org" target="_blank">
                    <Button
                      type="primary"
                      css={css`
                        margin-top: 10px;
                      `}
                    >
                      Learn More
                    </Button>
                  </a>
                </div>
              </div>
              {/* <div>
            <h5 className="mt-3 font-medium mb-2 normal-case">Frameworks</h5>
          </div> */}
              <div className="grid grid-cols-2 gap-x-3 gap-y-3">
                <Code
                  type="Framework"
                  owner="allenai"
                  name="objaverse-xl"
                  description="Scripts for Downloading and Processing Objaverse-XL"
                  language="Python"
                  emoji="ringed_planet"
                />
                <Code
                  type="Framework"
                  owner="allenai"
                  name="ai2thor"
                  description="Interactive Simulated Environments for Embodied AI"
                  language="Unity"
                  emoji="couch_and_lamp"
                />
                <Code
                  type="Framework"
                  owner="allenai"
                  name="procthor"
                  description="Procedurally Generate Houses for Embodied AI Training"
                  language="Python"
                  emoji="house_with_garden"
                />
                <Code
                  type="Framework"
                  owner="allenai"
                  name="objaverse-rendering"
                  description="Scripts for rendering Objaverse"
                  language="Python"
                  emoji="camera_with_flash"
                />
                <Code
                  type="Framework"
                  owner="allenai"
                  name="allenact"
                  description="A Framework for Training Embodied-AI Agents"
                  language="PyTorch"
                  emoji="muscle"
                />
                <Code
                  type="Template"
                  owner="allenai"
                  name="ai2thor-rearrangement"
                  description="Code for Running the Visual Room Rearrangement task"
                  language="PyTorch"
                  emoji="chair"
                />
                {/* </div>
          <div>
            <h5 className="mt-3 font-medium mb-2 normal-case">Tools</h5>
          </div>
          <div className="grid grid-cols-2 gap-x-3"> */}
                <Code
                  type="Tool"
                  owner="allenai"
                  name="prior"
                  description="Python Package for Distributing Datasets and Models"
                  language="Python"
                  emoji="snake"
                />
                <Code
                  type="Tool"
                  owner="allenai"
                  name="ai2thor-colab"
                  description="Run AI2-THOR with Google Colab"
                  language="Colab"
                  emoji="rocket"
                />
                {/* </div>
          <div>
            <h5 className="mt-3 font-medium mb-2 normal-case">Datasets</h5>
          </div>
          <div className="grid grid-cols-2 gap-x-3"> */}
                <Code
                  type="Dataset"
                  owner="allenai"
                  name="procthor-10k"
                  description="The ProcTHOR-10K Houses Dataset"
                  language="Python"
                  emoji="house_buildings"
                />
                <Code
                  type="Dataset"
                  owner="allenai"
                  name="object-nav-eval"
                  description="Evaluation tasks for ObjectNav models"
                  language="Python"
                  // emoji="house_buildings"
                />
                {/* </div>
          <div>
            <h5 className="mt-3 font-medium mb-2 normal-case">Websites</h5>
          </div> */}
                {/* <div className="grid grid-cols-2 gap-x-3"> */}
                <Code
                  type="Website"
                  owner="embodied-ai-workshop"
                  name="embodied-ai.org"
                  description="The Website for the Embodied AI Workshop at CVPR"
                  language="React"
                  emoji="earth_americas"
                />
                <Code
                  type="Website"
                  owner="RAIVNLab"
                  name="RAIVNLab.github.io"
                  description="Website for the UW RAIVN Lab"
                  language="React"
                  emoji="bird"
                />
                <Code
                  type="Website"
                  owner="mattdeitke"
                  name="cvpr-buzz"
                  description="Explore Trending Papers at CVPR 2021"
                  language="React"
                  emoji="bee"
                />
                <Code
                  type="Website"
                  owner="mattdeitke"
                  name="CVPR2019"
                  description="Explore and Parse Papers at CVPR 2019"
                  language="Python"
                  emoji="palm_tree"
                />
              </div>
            </Section>
            <Section title="Workshops">
              <div>
                <div
                  css={css`
                    width: 65%;
                    display: inline-block;
                    vertical-align: top;
                  `}
                >
                  <img
                    src={EAI_COVER}
                    css={css`
                      border-radius: 5px;
                      filter: drop-shadow(0px 0px 38px #d9d9d9);
                      -webkit-filter: drop-shadow(0px 0px 38px #d9d9d9);
                    `}
                  />
                </div>
                <div
                  css={css`
                    width: 35%;
                    display: inline-block;
                    padding-left: 30px;
                    vertical-align: top;
                  `}
                >
                  <h3
                    css={css`
                      margin-top: 0px;
                    `}
                  >
                    Embodied AI
                  </h3>
                  <p>
                    I've co-organized the Embodied AI workshops at CVPR. Our
                    goal is to bring together researchers to share and discuss
                    the current state of intelligent agents that can see, talk,
                    act, and reason.
                  </p>
                  <a href="//embodied-ai.org" target="_blank">
                    <Button
                      type="primary"
                      css={css`
                        margin-top: 10px;
                      `}
                    >
                      Learn More
                    </Button>
                  </a>
                </div>
              </div>
            </Section>
            <div className="h-16" />
          </div>
        </div>
      </div>
      {/* <div
        css={css`
          width: 95%;
          max-width: 850px;
          margin: auto;
          margin-top: 50px;
          margin-bottom: 100px;
        `}
      ></div> */}
    </>
  );
}
